import React, {FC, useEffect, useState} from "react";
import {Page} from "../components/layout/Page";
import {HistoryTable} from "../components/history/HistoryTable";
import {useUserTransfers} from "../features/user_transfers/useUserTransfers";
import {
    resetUserTransfers,
    setMoneyTransfersToMyself,
    setUsersTransfersInfo, setUserTransfers
} from "../features/user_transfers/UserTransfersSlice";
import {useAppDispatch} from "../store";
import {getFirstDayOfCurrentMonth} from "../features/common_funcs/time_util/time.funcs";
import dayjs, {Dayjs} from "dayjs";
import {TimeFilter} from "../components/common/time_filter/TimeFilter";
import {Box, Button, CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Stack} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {MyselfHistoryTable} from "../components/history/MyselfHistoryTable";
import {Paginator} from "../components/paginator/Paginator";

export const HistoryPage: FC = () => {
    const {
        getUserTransfersByAgent,
        pageSize, currentPage,
        filter,
        isUserTransfersLoading,
        userTransfers,
        getMoneyTransfersToMyself,
        transfersToMyself,
        totalPages
    } = useUserTransfers()
    const [justMounted, setJustMounted] = useState(true)
    const dispatch = useAppDispatch()

    const firstDayOfMonth = getFirstDayOfCurrentMonth()
    const currentDay = dayjs()

    const fromTimeDefault = filter.fromTime ? dayjs.unix(filter.fromTime) : firstDayOfMonth
    const toTimeDefault = filter.toTime ? dayjs.unix(filter.toTime) : null

    const [dateFrom, setDateFrom] = useState<Dayjs | null>(fromTimeDefault);
    const [dateTo, setDateTo] = useState<Dayjs | null>(toTimeDefault);

    const [modeNotApplied, setModeNotApplied] = useState<'purchase' | 'sell'>('purchase')
    const [mode, setMode] = useState<'purchase' | 'sell'>('purchase')

    const handleChangeMode = (event: SelectChangeEvent<"purchase" | "sell">) => {
        setModeNotApplied(event.target.value as 'purchase' | 'sell');
    };

    const handleApply = () => {
        setMode(modeNotApplied)
        if (modeNotApplied === 'purchase') {
            getMoneyTransfersToMyself(currentPage, pageSize, {
                ...filter,
                fromTime: dateFrom !== null ?  dateFrom.unix() : null,
                toTime: dateTo !== null ? dateTo.unix() : null
            })
            dispatch(setUserTransfers(null))
            dispatch(setUsersTransfersInfo({
                totalDeposits: null,
                totalWithdraws: null
            }))
        } else {
            getUserTransfersByAgent(currentPage, pageSize, null, {
                ...filter,
                fromTime: dateFrom !== null ?  dateFrom.unix() : null,
                toTime: dateTo !== null ? dateTo.unix() : null
            })
            dispatch(setMoneyTransfersToMyself(null))
        }
    }

    useEffect(() => {
        if (justMounted && !isUserTransfersLoading && transfersToMyself === null) {
            getMoneyTransfersToMyself(currentPage, pageSize, filter)
            setJustMounted(false)
        }
    }, [getUserTransfersByAgent, currentPage, pageSize, filter, justMounted, isUserTransfersLoading, userTransfers, dispatch, getMoneyTransfersToMyself, transfersToMyself])

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch]);

    return <Page>
        <Stack gap={1} p={1}>
            <TimeFilter
                fromTime={dateFrom}
                toTime={dateTo}
                setFromTime={setDateFrom}
                setToTime={setDateTo}
            />

            <FormControl size={'small'}>
                <Select
                    labelId="mode-select-label"
                    value={modeNotApplied}
                    onChange={handleChangeMode}
                >
                    <MenuItem value="purchase">Achat</MenuItem>
                    <MenuItem value="sell">Vente</MenuItem>
                </Select>
            </FormControl>

            <Button
                onClick={handleApply}
                fullWidth
                variant={'contained'}
                sx={{
                    backgroundColor: '#1877F2',
                    color: 'white',
                    textTransform: 'none',
                    gap: 1
                }}
            >
                <SearchIcon/>
                Afficher
                {isUserTransfersLoading && <CircularProgress
                    color={'inherit'}
                    size={18}
                    sx={{ml: 1}}
                />}
            </Button>


            {mode === 'purchase' ? <>
                <HistoryTable selfMode/>
            </> :
                <MyselfHistoryTable/>
            }
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Paginator changePage={newPage => {
                    if (mode === 'purchase') {
                        getMoneyTransfersToMyself(newPage, pageSize, filter)
                    } else {
                        getUserTransfersByAgent(newPage, pageSize, null, filter)
                    }
                }} currentPage={currentPage} totalPages={totalPages}/>
            </Box>

        </Stack>
    </Page>
}