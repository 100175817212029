import React, {FC} from "react";
import {Button, CircularProgress, Stack, TextField} from "@mui/material";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {DialogSubheader} from "../common/dialog_components/DialogSubheader";
import {useUser} from "../../features/user/useUser";

export const Login: FC = () => {
    const { logIn, isLogInLoading } = useUser()
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    const handleLogIn = () => {
        logIn(username, password)
    }

    return <Stack>
        <DialogSubheader icon={VpnKeyOutlinedIcon}>
            Se connecter
        </DialogSubheader>
        <Stack p={2} gap={2}>
            <TextField
                value={username}
                onChange={event => setUsername(event.target.value)}
                size={'small'}
                placeholder={'Utilisateur'}
            />
            <TextField
                value={password}
                onChange={event => setPassword(event.target.value)}
                size={'small'}
                placeholder={'Mot de passe'}
                type={'password'}
            />
            <Button
                onClick={handleLogIn}
                fullWidth
                variant={'contained'}
                sx={{
                    textTransform: 'none',
                    gap: 1
                }}
            >
                Me connecter
                {isLogInLoading && <CircularProgress
                    color={'inherit'}
                    size={18}
                    sx={{ml: 1}}
                />}
            </Button>
        </Stack>
    </Stack>
}