import {FC, ReactElement} from "react";
import {Box, TabProps} from "@mui/material";

export const TabBar: FC<{children?: ReactElement<TabProps> | ReactElement<TabProps>[]}> = ({children}) => {
    return <Box
        display={'flex'}
        alignItems={'center'}
        sx={theme => ({backgroundColor: theme.palette.customColors.tabBar.background, color: theme.palette.customColors.tabBar.color})}
    >
        {children}
    </Box>
}