import { FC, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { clearToken, clearUser } from "../../../features/user/userSlice";
import {removeError} from "../../../features/apiErrors/errorSlice";

export const ApiErrorDialog: FC = () => {
    const dispatch = useAppDispatch()
    const error = useAppSelector(state => state.errors.apiError ?? null)

    useEffect(() => {
        if (error?.code === 12) {
            dispatch(clearToken())
            dispatch(clearUser())
            dispatch(removeError())
        }
    }, [ dispatch, error ])

    return <Dialog maxWidth='xs' fullWidth open={Boolean(error) && error?.code !== 12} onClick={() => {
        dispatch(removeError())
    }}>
        <DialogTitle
            sx={theme => ({
                backgroundColor: theme.palette.error.main,
                color: theme.palette.getContrastText(theme.palette.error.main),
                mb: '15px',
                py: '6px',
                fontWeight: 600
            })}
        >
            {error && (`Code d'erreur: ${error.code}`)}
        </DialogTitle>
        <DialogContent>
            {error && error.message}
        </DialogContent>
        <DialogActions>
            <Button autoFocus color={'error'} variant={'contained'}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}