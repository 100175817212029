import { Box, Typography, alpha } from "@mui/material";
import { FC } from "react";
import {ProfileMenuItemProps} from "./ProfileMenuItem.interface";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const ProfileMenuItem: FC<ProfileMenuItemProps> = (props) => {
    return props.link ? <Link to={props.link}>
        <ProfileMenuItemBase {...props}/>
    </Link> : <ProfileMenuItemBase {...props}/>
}

const ProfileMenuItemBase: FC<ProfileMenuItemProps> = ({icon: Icon, title, onClick}) => {
    return <Box
        width={'100%'}
        onClick={onClick}
        display={'flex'}
        px={1}
        gap={1}
        sx={{
            cursor: 'pointer'
        }}
    >
        <Box
            p={1}
            height={'100%'}
            sx={{
                aspectRatio: '1/1',
            }}
        >
            <Icon
                sx={theme => ({
                    fontSize: '36px',
                    backgroundColor: theme.palette.customColors.profileMenuIcon.background,
                    color: theme.palette.customColors.profileMenuIcon.color,
                    p: 1,
                    borderRadius: '50%'
                })}
            />
        </Box>
        <Box
            className={'profile-menu-item-divider'}
            flexGrow={1}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Typography mt={'-4px'} fontSize={'14px'}>
                {title}
            </Typography>
            <ArrowForwardIosIcon
                sx={theme => ({
                    fontSize: '14px',
                    color: alpha(theme.palette.text.primary, 0.5)
                })}
            />
        </Box>
    </Box>
}