import {FC, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useUserTransfers} from "../../features/user_transfers/useUserTransfers";
import {convertCentsToCurrencyString} from "../../features/common_funcs/common_funcs";

export const MyselfHistoryTable: FC = () => {
    const { userTransfers, usersTransfersInfo } = useUserTransfers()
    const [expanded, setExpanded] = useState(true);

    const formatDate = (date: string) => {
        const [datePart, timePart] = date.split(' ')
        const [hour, minute] = timePart.split(':')
        return `${datePart} ${hour}:${minute}`.replaceAll('.', '-')
    }

    return <>
        <TableContainer
            sx={theme => ({
                '& .MuiTableCell-head': {
                    backgroundColor: theme.palette.customColors.alternativeTableHeader.background,
                    color:  theme.palette.customColors.alternativeTableHeader.text,
                    cursor: 'pointer'
                },
                '& .MuiTableCell-body': {
                    fontWeight: 700,
                },
                '& .MuiTableCell-root': {
                    py: '10px',
                    fontSize: '13px'
                },
                '& .MuiTableCell-root:not(:last-child)': {
                    borderRight: '1px solid #e0e0e0'
                }
            })}
        >
            <Table>
                <TableHead>
                    <TableRow onClick={() => setExpanded(prevState => !prevState)}>
                        <TableCell size={'small'} align={'center'} width={'35%'}>
                            Utilisateur
                        </TableCell>
                        <TableCell size={'small'} align={'center'} width={'35%'}>
                            Montant
                        </TableCell>
                        <TableCell size={'small'} align={'center'} width={'30%'}>
                            date
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {expanded && userTransfers && userTransfers.map((transfer) =>
                        <TableRow key={transfer.transfer_id}>
                            <TableCell size={'small'} align={'center'}>
                                {transfer.user_name}
                            </TableCell>
                            <TableCell size={'small'} align={'center'} sx={{color: transfer.transfer_type === 'withdraw' ? '#bb4748' : '#718355'}}>
                                {transfer.transfer_type === 'withdraw' && '-'}{`${convertCentsToCurrencyString(transfer.transfer_amount)} TND`}
                            </TableCell>
                            <TableCell size={'small'} align={'center'}>
                                {formatDate(transfer.time)}
                            </TableCell>
                        </TableRow>)}

                    <TableRow
                        sx={theme => ({
                            '& .MuiTableCell-body': {
                                backgroundColor: theme.palette.customColors.tableHeader.background,
                                color: theme.palette.customColors.tableHeader.text,
                            },
                        })}
                    >
                        <TableCell colSpan={2} size={'small'} align={'center'}>
                            Dépôt total
                        </TableCell>
                        <TableCell size={'small'} align={'center'}>
                            {usersTransfersInfo?.totalDeposits && `${convertCentsToCurrencyString(usersTransfersInfo.totalDeposits)} TND`}
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={theme => ({
                            '& .MuiTableCell-body': {
                                backgroundColor: theme.palette.customColors.withdrawCell.background,
                                color: theme.palette.customColors.withdrawCell.text,
                            },
                        })}
                    >
                        <TableCell colSpan={2} size={'small'} align={'center'}>
                            Retrait total
                        </TableCell>
                        <TableCell size={'small'} align={'center'}>
                            {usersTransfersInfo?.totalWithdraws && `${convertCentsToCurrencyString(usersTransfersInfo.totalWithdraws)} TND`}
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={theme => ({
                            '& .MuiTableCell-body': {
                                backgroundColor: theme.palette.customColors.netwinCell.background,
                                color: theme.palette.customColors.netwinCell.text,
                            },
                        })}
                    >
                        <TableCell colSpan={2} size={'small'} align={'center'}>
                            Net
                        </TableCell>
                        <TableCell size={'small'} align={'center'}>
                            {usersTransfersInfo?.totalWithdraws && usersTransfersInfo?.totalDeposits && `${convertCentsToCurrencyString(usersTransfersInfo.totalDeposits - usersTransfersInfo.totalWithdraws)} TND`}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </>
}