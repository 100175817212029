import React from 'react';
import {Route, Routes} from "react-router-dom";
import {MediaqueryIndicator} from "./app/components/dev/MediaqueryIndicator";
import {HomePage} from "./app/pages/HomePage";
import {ProfilePage} from "./app/pages/ProfilePage";
import {ChangePasswordPage} from "./app/pages/ChangePasswordPage";
import {HistoryPage} from "./app/pages/HistoryPage";
import {useUserInit} from "./app/features/user/useUserInit";

const App = () => {
    useUserInit()

    return <>
        <MediaqueryIndicator position={'bottom-left'}/>
        <Routes>
            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/profile'} element={<ProfilePage/>}/>
            <Route path={'/profile/manage_password'} element={<ChangePasswordPage/>}/>
            <Route path={'/profile/transactions'} element={<HistoryPage/>}/>
        </Routes>
    </>
}

export default App;
