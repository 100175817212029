import {TransferMyself, UserTransfer, UserTransfersInfo} from "./models";
import { UserTransfersFilter } from "./filter";

export const transactionsPageSizeDefault = 100

export interface UserTransfersState {
    userTransfers: UserTransfer[] | null;
    transfersToMyself: TransferMyself[] | null;
    filter: UserTransfersFilter;
    usersTransfersInfo: UserTransfersInfo
    pageSize: number;
    currentPage: number;
    totalRecords: number;
    totalPages: number;
    isUserTransfersLoading: boolean;
    isMoneyTransfersToMyselfLoading: boolean
}