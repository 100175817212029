import React, {FC, useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, Dialog, Stack, TextField} from "@mui/material";
import {DialogHeader} from "../common/dialog_components/DialogHeader";
import {DialogTransition} from "../common/transitions/DialogTransition";
import {TabBar} from "../common/tab_bar/TabBar";
import {Tab} from "../common/tab_bar/Tab";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import {DialogSubheader} from "../common/dialog_components/DialogSubheader";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {QuickAmounts} from "../quick_amounts/QuickAmounts";
import {DepositWithdraw} from "../deposit_withdraw/DepositWithdraw";
import {convertCentsToCurrencyString, convertStringCurrencyToCents} from "../../features/common_funcs/common_funcs";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {HistoryTable} from "../history/HistoryTable";
import {User} from "../../features/user/user";
import {useUserTransfers} from "../../features/user_transfers/useUserTransfers";
import {useAppDispatch} from "../../store";
import { resetUserTransfers } from "../../features/user_transfers/UserTransfersSlice";
import {useUser} from "../../features/user/useUser";
import {usePlayersAgents} from "../../features/manage_players_agents/usePlayersAgents";
import DoneIcon from '@mui/icons-material/Done';
import {TimeFilter} from "../common/time_filter/TimeFilter";
import dayjs, {Dayjs} from "dayjs";
import {getFirstDayOfCurrentMonth} from "../../features/common_funcs/time_util/time.funcs";
import SearchIcon from '@mui/icons-material/Search';
import {Paginator} from "../paginator/Paginator";

export const UserInfo: FC<{close?: () => void, user: User}> = ({close, user}) => {
    const [activeTab, setActiveTab] = React.useState<number>(0)
    const [ opened, setOpened ] = useState(true)
    const [ depositWithdrawAmount, setDepositWithdrawAmount ] = useState<string>('')
    const [userBalance, setUserBalance] = useState<number>(user.user_balance)
    const { getUserTransfersByAgent, pageSize, currentPage, filter, isUserTransfersLoading, totalPages } = useUserTransfers()
    const prevActiveTab = useRef<number>(0)
    const dispatch = useAppDispatch()
    const { subLogin, isLogInLoading } = useUser()
    const { changeUserPassword, banUser } = usePlayersAgents()
    const [userPassword, setUserPassword] = useState<string>('')
    const [isUserPasswordChangeLoading, setIsUserPasswordChangeLoading] = useState<boolean>(false)
    const [userBanned, setUserBanned] = useState<boolean>(user.banned)
    const [isUserBanLoading, setIsUserBanLoading] = useState<boolean>(false)

    const firstDayOfMonth = getFirstDayOfCurrentMonth()
    const currentDay = dayjs()

    const fromTimeDefault = filter.fromTime ? dayjs.unix(filter.fromTime) : firstDayOfMonth
    const toTimeDefault = filter.toTime ? dayjs.unix(filter.toTime) : null

    const [dateFrom, setDateFrom] = useState<Dayjs | null>(fromTimeDefault);
    const [dateTo, setDateTo] = useState<Dayjs | null>(toTimeDefault);

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close?.(), 500)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isValidNumber = /^\d*(\.\d*)?$/.test(value);
        if (isValidNumber) {
            setDepositWithdrawAmount(value);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isValidKey = /^\d$/.test(event.key) || event.key === '.' || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight';
        if (!isValidKey) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        if (activeTab !== prevActiveTab.current) {
            if (activeTab === 1) {
                getUserTransfersByAgent(currentPage, pageSize, null, {
                    ...filter,
                    username: user.user_name,
                    fromTime: dateFrom !== null ?  dateFrom.unix() : null,
                    toTime: dateTo !== null ? dateTo.unix() : null
                })
            } else {
                dispatch(resetUserTransfers())
            }
            prevActiveTab.current = activeTab;
        }
    }, [activeTab, currentPage, dateFrom, dateTo, dispatch, filter, getUserTransfersByAgent, pageSize, user.user_id, user.user_name]);

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch]);

    const handleSublogin = () => {
        if (isLogInLoading) return
        subLogin(user.user_id)
    }

    const handlePasswordChange = () => {
        if (userPassword.length === 0) return
        changeUserPassword(user.user_id, userPassword, () => {
            setUserPassword('')
        }, setIsUserPasswordChangeLoading)
    }

    const handleUserBan = () => {
        if (isUserBanLoading) return
        banUser(user.user_id, !userBanned, setIsUserBanLoading, (isChecked) => {
            setUserBanned(isChecked)
        })
    }

    return <Dialog
        TransitionComponent={DialogTransition}
        open={opened}
        onClose={pendingClose}
        sx={{
            '& .MuiDialog-paper': {
                maxWidth: '1000px',
                width: '100%',
                height: '100dvh',
                overflow: 'auto',
                maxHeight: '100%',
                m: 0
            }
        }}
    >
        <DialogHeader onCloseClick={pendingClose}>
            <Box
                flex={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {user.user_name}
            </Box>
            <Box
                sx={{
                    borderLeft: '1px solid',
                    borderColor: 'black',
                    height: '50%',
                }}
            />
            <Box
                flex={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {convertCentsToCurrencyString(userBalance)}
            </Box>
        </DialogHeader>

        <TabBar>
            <Tab
                icon={PriceChangeIcon}
                onClick={() => setActiveTab(0)}
                active={activeTab === 0}
                label={'Paiement'}
            />
            <Tab
                icon={ListOutlinedIcon}
                onClick={() => setActiveTab(1)}
                active={activeTab === 1}
                label={'Historique'}
            />
        </TabBar>


        {activeTab === 0 && <>
            <DialogSubheader icon={PriceChangeIcon}>
                Paiement
            </DialogSubheader>

            <TextField
                value={depositWithdrawAmount.replace(',', '.')}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={'Montant'}
                size={'small'}
                type="number"
                slotProps={{
                    input: {
                        inputProps: {
                            step: 1,
                            min: 0,
                            max: 100,
                        },
                    },
                }}
                sx={{
                    m: 2
                }}
            />

            <QuickAmounts
                currentAmount={convertStringCurrencyToCents(depositWithdrawAmount)}
                onAmountChanged={(value) => {
                    setDepositWithdrawAmount(convertCentsToCurrencyString(value, false, true))
                }}
            />

            <DepositWithdraw
                amount={convertStringCurrencyToCents(depositWithdrawAmount)}
                disabled={depositWithdrawAmount === ''}
                recipientID={user.user_id}
                onTransactionDone={(child_balance, parent_balance) => {
                    setUserBalance(child_balance)
                    setDepositWithdrawAmount('')
                }}
            />

            <DialogSubheader icon={VpnKeyOutlinedIcon}>
                Mot de passe
            </DialogSubheader>

            <Stack p={3} gap={1}>
                <TextField
                    value={userPassword}
                    onChange={event => setUserPassword(event.target.value)}
                    placeholder={'Mot de passe'}
                    size={'small'}
                    disabled={isUserPasswordChangeLoading}
                />

                <Button
                    onClick={handlePasswordChange}
                    fullWidth
                    variant={'contained'}
                    sx={{
                        backgroundColor: '#6236FF',
                        textTransform: 'none',
                        gap: 1
                    }}
                >
                    <DriveFileRenameOutlineIcon/>
                    Modifier
                    {isUserPasswordChangeLoading && <CircularProgress
                        color={'inherit'}
                        size={18}
                        sx={{ml: 1}}
                    />}
                </Button>
            </Stack>

            <DialogSubheader icon={Person2OutlinedIcon}>
                Compte
            </DialogSubheader>

            <Stack p={3} gap={1}>
                <Button
                    onClick={handleUserBan}
                    fullWidth
                    variant={'contained'}
                    sx={{
                        backgroundColor: '#FF396F',
                        textTransform: 'none',
                        gap: 1
                    }}
                >
                    {userBanned ? <>
                        <DoneIcon/>
                            Activer
                    </>
                        :
                        <>
                        <CancelOutlinedIcon/>
                            Desactiver
                        </>}
                    {isUserBanLoading && <CircularProgress
                        color={'inherit'}
                        size={18}
                        sx={{ml: 1}}
                    />}
                </Button>

                <Button
                    onClick={handleSublogin}
                    fullWidth
                    variant={'contained'}
                    sx={{
                        backgroundColor: '#c31bf1',
                        textTransform: 'none',
                        gap: 1
                    }}
                >
                    <LoginOutlinedIcon/>
                    Se connecter
                    {isLogInLoading && <CircularProgress
                        color={'inherit'}
                        size={18}
                        sx={{ml: 1}}
                    />}
                </Button>
            </Stack>
        </>}

        {activeTab === 1 && <Stack gap={1} p={1}>
                <TimeFilter
                    fromTime={dateFrom}
                    toTime={dateTo}
                    setFromTime={setDateFrom}
                    setToTime={setDateTo}
                />
            <Button
                onClick={() => {
                    getUserTransfersByAgent(currentPage, pageSize, null, {...filter, fromTime: dateFrom !== null ?  dateFrom.unix() : null, toTime: dateTo !== null ? dateTo.unix() : null})
                }}
                fullWidth
                variant={'contained'}
                sx={{
                    backgroundColor: '#1877F2',
                    color: 'white',
                    textTransform: 'none',
                    gap: 1
                }}
            >
                <SearchIcon/>
                Afficher
                {isUserTransfersLoading && <CircularProgress
                    color={'inherit'}
                    size={18}
                    sx={{ml: 1}}
                />}
            </Button>
            <HistoryTable/>
            <Box width={'100%'} flex={0} display={'flex'} justifyContent={'center'}>
                <Paginator changePage={newPage => {
                        getUserTransfersByAgent(newPage, pageSize, null, filter)
                }} currentPage={currentPage} totalPages={totalPages}/>
            </Box>
        </Stack>}

    </Dialog>
}

