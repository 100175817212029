import {FC, useState} from "react";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import {IconButton} from "@mui/material";
import {AddUserModal} from "./AddUserModal";

export const AddUserActivator: FC = () => {
    const [open, setOpen] = useState(false)

    return <>
        <IconButton color={'inherit'} onClick={() => setOpen(true)}>
            <PersonAddOutlinedIcon fontSize={'large'}/>
        </IconButton>

        {open && <AddUserModal close={() => setOpen(false)}/>}
    </>
}