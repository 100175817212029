import {FC} from "react";
import {Box, Button} from "@mui/material";
import {QuickAmountsProps} from "./QuickAmounts.interface";
import {useUser} from "../../features/user/useUser";
import {UserRole} from "../../features/user/user";

export const QuickAmounts: FC<QuickAmountsProps> = ({currentAmount = 0, onAmountChanged}) => {
    const { userRole } = useUser()
    const handleClick = (value: number) => () => {
        onAmountChanged?.(currentAmount + (value * 100))
    }

    const amount = userRole === UserRole.OWNER || userRole === UserRole.PARTNER || userRole === UserRole.OWNERL ||  userRole === UserRole.ADMIN || userRole === UserRole.SUPERADMIN ? [500, 1000, 5000, 10000] : [1, 3, 5, 10]

    return <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
    >
        {amount.map((amount, index) => <Button
            key={amount + index}
            onClick={handleClick(amount)}
            size={'small'}
            variant={'outlined'}
            sx={theme => ({
                borderColor: theme.palette.primary.main,
                minWidth: '50px',
                py: 0,
                px: 1
            })}
        >
            {amount}
        </Button>)}
    </Box>
}