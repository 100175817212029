import {FC} from "react";
import {TabProps} from "./Tab.interface";
import {Box, Typography} from "@mui/material";

export const Tab: FC<TabProps> = ({label, icon: Icon, active, onClick}) => {
    return <Box
        onClick={onClick}
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={'8px'}
        gap={1}
        m={0.5}
        minHeight={'30px'}
        boxShadow={active ? '1px 1px 3px #0004' : undefined}
        sx={theme => ({
            color: active ? theme.palette.customColors.tabBar.knob.color : 'inherit',
            backgroundColor: active ? theme.palette.customColors.tabBar.knob.background : undefined,
            cursor: !active ? 'pointer' : undefined,
            userSelect: 'none'
        })}
    >
        {Icon && <Icon/>}
        <Typography fontSize={'13px'} fontWeight={500}>
            {label}
        </Typography>
    </Box>
}