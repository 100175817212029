import { User } from "./user";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import axios from "axios";
import {
    clearCurrency,
    clearToken,
    clearUser,
    setChangePasswordLoading, setDisplayCurrency,
    setLogInLoading,
    setToken,
    setUser,
    setUserInfoUpdateLoading
} from "./userSlice";
import {
    GetBalanceResponse,
    iUseLogin,
    LoginResponse,
    UserInfoResponse
} from "./useUserInterfaces";
import Config, {getApiHost} from "../../config";
import {ApiResponse} from "../common_funcs/apiResponseModels";
import {addNewError} from "../apiErrors/errorSlice";


export const useUser = (): iUseLogin => {
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.user)
    const { token, user} = userState
    const apiURL = getApiHost()

    const logIn = useCallback((username: string, password: string) => {
        if (apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'Login')
            data.append('username', username)
            data.append('password', password)

            dispatch(setLogInLoading(true))

            axios.post<LoginResponse>(apiURL, data)
                .then(response => {
                    const { success, error, token, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                            if (user.user_currency) {
                                dispatch(setDisplayCurrency(user.user_currency))
                                // TODO set exchange rate
                                window.location.reload()
                            }
                        }
                        if (token) {
                            dispatch(setToken(token))
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
                .finally(() => {
                    dispatch(setLogInLoading(false))
                })
        }
    }, [apiURL, dispatch])

    const updateUserInfo = useCallback((newUserInfo: User) => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateUserInformation')
            data.append('token', token)
            if ((newUserInfo.user_email && newUserInfo.user_email.trim() !== '') || user.user_email) data.append('useremail', newUserInfo.user_email ?? user.user_email ?? '')
            data.append('userphone', newUserInfo.user_phone ?? '')
            data.append('userfirstname', newUserInfo.user_firstname ?? '')
            data.append('userlastname', newUserInfo.user_lastname ?? '')

            dispatch(setUserInfoUpdateLoading(true))

            axios.post<UserInfoResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
                .finally(() => {
                    dispatch(setUserInfoUpdateLoading(false))
                })
        }
    }, [apiURL, dispatch, token, user])

    const logOut = useCallback(() => {
        if (token && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'logout')
            data.append('token', token)

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(clearUser())
                        dispatch(clearToken())
                        dispatch(clearCurrency())
                        window.location.reload()
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
        }
    }, [token, apiURL, dispatch])

    const changePassword = useCallback((oldPassword: string, newPassword: string, onSuccess: () => void = () => {}) => {
        if (token && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ChangePassword')
            data.append('token', token)

            data.append('password', oldPassword)
            data.append('newpassword', newPassword)

            dispatch(setChangePasswordLoading(true))

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
                .finally(() => {
                    dispatch(setChangePasswordLoading(false))
                })
        }
    }, [token, apiURL, dispatch])

    const refreshUserInfo = useCallback(() => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowOneUser')
            data.append('token', token)
            data.append('userid', user.user_id.toString())

            axios.post<UserInfoResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
        }
    }, [apiURL, dispatch, token, user])
    
    const getOneUser = useCallback((userID: number, onLoaderChange: (isLoading: boolean) => void = () => {}, onSuccess: (user: User) => void = () => {}) => {
        if (token && apiURL) {
            onLoaderChange(true)
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowOneUser')
            data.append('token', token)
            data.append('userid', userID.toString())

            axios.post<UserInfoResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            onSuccess(user)
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
                .finally(() => {
                    onLoaderChange(false)
                })
        }
    }, [apiURL, dispatch, token])

    const refreshUserBalance = useCallback(() => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetBalance')
            data.append('token', token)

            axios.post<GetBalanceResponse>(apiURL, data)
                .then(response => {
                    const { success, error, balance } = response.data
                    if (success) {
                        if (balance) {
                            const updatedUser: User = {
                                ...user,
                                user_balance: balance
                            }
                            dispatch(setUser(updatedUser))
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
        }
    }, [apiURL, dispatch, token, user])

    const subLogin = useCallback((userId: number) => {
        if (token && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'SubLogin')
            data.append('token', token)
            // data.append('username', username)
            // data.append('password', password)
            data.append('userid', userId.toString())

            dispatch(setLogInLoading(true))

            axios.post<LoginResponse>(apiURL, data)
                .then(response => {
                    const { success, error, token, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                            if (user.user_currency) {
                                dispatch(setDisplayCurrency(user.user_currency))
                                window.location.reload()
                            }
                        }
                        if (token) {
                            dispatch(setToken(token))
                        }
                    }
                    if (error) {
                        dispatch(addNewError(error))
                    }
                })
                .finally(() => {
                    dispatch(setLogInLoading(false))
                })
        }
    }, [apiURL, dispatch, token])

    return {
        ...userState,
        logIn,
        updateUserInfo,
        refreshUserInfo,
        refreshUserBalance,
        logOut,
        changePassword,
        getOneUser,
        subLogin
    }
}