import { iUseUserTransfers } from "./models/hookInterface";
import { useCallback } from "react";
import { UserTransfersFilter } from "./models/filter";
import axios, { AxiosError } from "axios";
import Config, { getApiHost } from "../../config";
import { useAppDispatch, useAppSelector } from "../../store";
import { useUser } from "../user/useUser";
import {
    setMoneyTransfersToMyself,
    setUsersTransfersInfo,
    setUserTransfers,
    setUserTransfersCurrentPage,
    setUserTransfersFilter,
    setUserTransfersItemsPerPage, setUserTransfersLoader, setUserTransfersTotalRecords
} from "./UserTransfersSlice";
import { UserTransfersLoaders } from "./models/loaders";
import {ShowMoneyTransfersToMyselfResponse, ShowUserTransfersByAgent} from "./models/responses";
import {convertUnixToCompactLocaleDate} from "../common_funcs/common_funcs";
import {addNewError} from "../apiErrors/errorSlice";
import {transactionsPageSizeDefault} from "./models/state";

export const useUserTransfers = () : iUseUserTransfers => {
    const apiURL = getApiHost()
    const dispatch = useAppDispatch();
    const { token } = useUser();
    const state = useAppSelector(state => state.userTransfers);

    const getUserTransfersByAgent = useCallback((page: number, itemsPerPage: number, childID?: number|null, filter?: UserTransfersFilter|null) => {
        let filterForSending = state.filter
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime
                || filter.fromTime !== state.filter.fromTime
                || filter.typeTransaction !== state.filter.typeTransaction
                || filter.username !== state.filter.username
                || filter.userRole !== state.filter.userRole
                || filter.direct !== state.filter.direct
            ) {
                dispatch(setUserTransfersFilter(filter))
                filterForSending = filter
            }

            let itemsPerPageToSend = state.pageSize
            if (itemsPerPage !== state.pageSize) {
                dispatch(setUserTransfersItemsPerPage(itemsPerPage ?? transactionsPageSizeDefault))
                itemsPerPageToSend = itemsPerPage ?? transactionsPageSizeDefault
            }

            let pageToSend = state.currentPage
            if (page !== state.currentPage) {
                dispatch(setUserTransfersCurrentPage(page))
                pageToSend = page
            }

            if (token && apiURL) {
                dispatch(setUserTransfersLoader({
                    loader: UserTransfersLoaders.isUserTransfersLoading,
                    isLoading: true
                }))

                axios.create({ ...Config.axiosConfig })

                const data = new FormData();
                data.append('action', 'SHOWUSERTRANSFERSBYAGENT')
                data.append('token', token)

                data.append('page', pageToSend.toString())
                data.append('pagesize', itemsPerPageToSend.toString())

                if (filterForSending.fromTime !== null) {
                    data.append('fromtime', filterForSending.fromTime.toString())
                }
                if (filterForSending.toTime !== null) {
                    data.append('totime', filterForSending.toTime.toString())
                }
                if (filterForSending.typeTransaction !== null) {
                    data.append('typetransaction', filterForSending.typeTransaction)
                }
                if (filterForSending.username !== null) {
                    data.append('username', filterForSending.username)
                }
                if (filterForSending.userRole !== null) {
                    data.append('userrole', filterForSending.userRole)
                }
                if (filterForSending.direct !== null) {
                    data.append('direct', filterForSending.direct ? 'True' : 'False')
                }
                if (childID) {
                    data.append('childid', childID.toString())
                }

                axios
                    .post<ShowUserTransfersByAgent>(apiURL, data)
                    .then(response => {
                        const { success, error, data: userTransfersList, total_transfers, total_withdraws, total_deposits } = response.data
                        if (success) {
                            if (userTransfersList) {
                                userTransfersList.forEach(userTransfer => {
                                    userTransfer.time = convertUnixToCompactLocaleDate(userTransfer.time_unix)
                                })
                                dispatch(setUserTransfers(userTransfersList))
                            }
                            if (total_transfers) {
                                dispatch(setUserTransfersTotalRecords(total_transfers))
                            }
                            if (total_withdraws !== undefined || total_deposits !== undefined) {
                                dispatch(setUsersTransfersInfo({
                                    totalDeposits: total_deposits ?? null,
                                    totalWithdraws: total_withdraws ?? null
                                }))
                            }
                        } else if (error) {
                            if (error.code === 2) {
                                dispatch(setUserTransfers([]))
                                dispatch(setUserTransfersTotalRecords(0))
                            } else {
                                dispatch(addNewError(error))
                            }
                        }
                    })
                    .catch((error: Error | AxiosError) => {
                        dispatch(setUserTransfers([]))
                        dispatch(setUserTransfersTotalRecords(0))
                    })
                    .finally(() => {
                        dispatch(setUserTransfersLoader({
                            loader: UserTransfersLoaders.isUserTransfersLoading,
                            isLoading: false
                        }))
                    })
            }
        }
    }, [apiURL, dispatch, state.currentPage, state.filter, state.pageSize, token])
    
    const getMoneyTransfersToMyself = useCallback((page: number, itemsPerPage: number, filter?: UserTransfersFilter|null) => {
        let filterForSending = state.filter
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime
                || filter.fromTime !== state.filter.fromTime
                || filter.typeTransaction !== state.filter.typeTransaction
                || filter.username !== state.filter.username
                || filter.userRole !== state.filter.userRole
                || filter.direct !== state.filter.direct
            ) {
                dispatch(setUserTransfersFilter(filter))
                filterForSending = filter
            }

            let itemsPerPageToSend = state.pageSize
            if (itemsPerPage !== state.pageSize) {
                dispatch(setUserTransfersItemsPerPage(itemsPerPage ?? transactionsPageSizeDefault))
                itemsPerPageToSend = itemsPerPage ?? transactionsPageSizeDefault
            }

            let pageToSend = state.currentPage
            if (page !== state.currentPage) {
                dispatch(setUserTransfersCurrentPage(page))
                pageToSend = page
            }

            if (token && apiURL) {
                dispatch(setUserTransfersLoader({
                    loader: UserTransfersLoaders.isMoneyTransfersToMyselfLoading,
                    isLoading: true
                }))

                axios.create({ ...Config.axiosConfig })

                const data = new FormData();
                data.append('action', 'SHOWMONEYTRANSFERSTOMYSELF')
                data.append('token', token)

                data.append('page', pageToSend.toString())
                data.append('pagesize', itemsPerPageToSend.toString())

                if (filterForSending.fromTime !== null) {
                    data.append('fromtime', filterForSending.fromTime.toString())
                }
                if (filterForSending.toTime !== null) {
                    data.append('totime', filterForSending.toTime.toString())
                }
                if (filterForSending.typeTransaction !== null) {
                    data.append('typetransaction', filterForSending.typeTransaction)
                }
                if (filterForSending.username !== null) {
                    data.append('username', filterForSending.username)
                }
                if (filterForSending.userRole !== null) {
                    data.append('userrole', filterForSending.userRole)
                }
                if (filterForSending.direct !== null) {
                    data.append('direct', filterForSending.direct ? 'True' : 'False')
                }

                axios
                    .post<ShowMoneyTransfersToMyselfResponse>(apiURL, data)
                    .then(response => {
                        const { success, error, data: moneyTransfersList, total_transfers } = response.data
                        if (success) {
                            if (moneyTransfersList) {
                                moneyTransfersList.forEach(moneyTransfer => {
                                    moneyTransfer.request_time = convertUnixToCompactLocaleDate(moneyTransfer.request_time_unix)
                                    moneyTransfer.respond_time = moneyTransfer.respond_time_unix !== null ? convertUnixToCompactLocaleDate(moneyTransfer.respond_time_unix) : null
                                })
                                dispatch(setMoneyTransfersToMyself(moneyTransfersList))
                            }
                            if (total_transfers) {
                                dispatch(setUserTransfersTotalRecords(total_transfers))
                            }
                        } else if (error) {
                            if (error.code === 2) {
                                dispatch(setMoneyTransfersToMyself([]))
                                dispatch(setUserTransfersTotalRecords(0))
                            } else {
                                dispatch(addNewError(error))
                            }
                        }
                    })
                    .catch((error: Error | AxiosError) => {
                        dispatch(setMoneyTransfersToMyself([]))
                        dispatch(setUserTransfersTotalRecords(0))
                    })
                    .finally(() => {
                        dispatch(setUserTransfersLoader({
                            loader: UserTransfersLoaders.isMoneyTransfersToMyselfLoading,
                            isLoading: false
                        }))
                    })
            }
        }
        
    }, [apiURL, dispatch, state.currentPage, state.filter, state.pageSize, token])

    return {
        ...state,
        getUserTransfersByAgent,
        getMoneyTransfersToMyself
    }
}