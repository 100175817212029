import { FC, ReactNode, ElementType } from "react";
import {alpha, Box, SvgIconProps, Typography} from "@mui/material";

export const DialogSubheader: FC<{icon?: ElementType<SvgIconProps>, children?: ReactNode}> = ({icon: Icon, children}) => {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
            p={1.5}
            sx={{
              borderTop: `1px solid ${alpha('#000', 0.2)}`,
                borderBottom: `1px solid ${alpha('#000', 0.2)}`
            }}
            fontSize={16}
            gap={0.5}
        >
            {Icon && <Icon fontSize={'inherit'}/>}
            <Typography component={'h2'} fontSize={'inherit'} align={'center'}>
                {children}
            </Typography>
        </Box>
    );
}