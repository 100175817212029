import React, {FC, useState} from "react";
import {Page} from "../components/layout/Page";
import {Button, CircularProgress, Stack, TextField} from "@mui/material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import {useUser} from "../features/user/useUser";

export const ChangePasswordPage: FC = () => {
    const { changePassword, isChangePasswordLoading } = useUser()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const handleChangePassword = () => {
        if (isChangePasswordLoading) return
        if (oldPassword === '' || newPassword === '') return
        changePassword(oldPassword, newPassword, () => {
            setOldPassword('')
            setNewPassword('')
        })
    }

    return <Page>
        <Stack p={2} gap={2}>
            <TextField
                value={oldPassword}
                onChange={event => setOldPassword(event.target.value)}
                type={'password'}
                size={'small'}
                placeholder={'Nouveau mot de passe'}
            />
            <TextField
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
                type={'password'}
                size={'small'}
                placeholder={'Répéter mot de passe'}
            />

            <Button
                onClick={handleChangePassword}
                fullWidth
                variant={'contained'}
                sx={{
                    textTransform: 'none',
                    gap: 1
                }}
            >
                <VpnKeyOutlinedIcon/>
                Confirmer
                {isChangePasswordLoading && <CircularProgress
                    color={'inherit'}
                    size={18}
                    sx={{ml: 1}}
                />}
            </Button>
        </Stack>
    </Page>
}