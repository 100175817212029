import React, {FC, useState} from "react";
import {DialogTransition} from "../common/transitions/DialogTransition";
import {Button, CircularProgress, Dialog, Stack, TextField} from "@mui/material";
import { DialogHeader } from "../common/dialog_components/DialogHeader";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import {usePlayersAgents} from "../../features/manage_players_agents/usePlayersAgents";
import {getSubRole} from "../../features/user/userFuncs";
import {useUser} from "../../features/user/useUser";

export const AddUserModal: FC<{close?: () => void}> = ({close}) => {
    const [ opened, setOpened ] = useState(true)
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const { createUser, isCreateUserLoading } = usePlayersAgents()
    const {user} = useUser()
    const {loadUsers, pageSize, filter, currentPage} = usePlayersAgents()

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close?.(), 500)
    }

    const handleAddUser = () => {
        if (username !== '' && password !== '' && user) {
            const subrole = getSubRole(user.user_role)
            createUser(username, subrole, password, () => {
                setTimeout(() => {
                    loadUsers(currentPage, pageSize, null, filter)
                    pendingClose()
                }, 500)
            })
        }
    }

    return <Dialog
        TransitionComponent={DialogTransition}
        open={opened}
        onClose={pendingClose}
        sx={{
            '& .MuiPaper-root': {
                maxWidth: '1000px',
                width: '100%',
                height: '100dvh',
                overflow: 'auto',
                maxHeight: '100%',
                m: 0
            }
        }}
    >
        <DialogHeader onCloseClick={pendingClose} title={'Ajouter client'}/>

        <Stack p={2} gap={2}>
            <TextField
                size={'small'}
                placeholder={'Utilisateur'}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={username === ''}
                helperText={username === '' ? 'Le nom d\'utilisateur ne doit pas être vide' : ''}
            />
            <TextField
                size={'small'}
                placeholder={'Mot de passe'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={password === ''}
                helperText={password === '' ? 'Le mot de passe ne doit pas être vide' : ''}
            />

            <Button
                onClick={handleAddUser}
                fullWidth
                variant={'contained'}
                sx={{
                    textTransform: 'none',
                    gap: 1
                }}
            >
                <PersonAddOutlinedIcon/>
                Ajouter
                {isCreateUserLoading && <CircularProgress
                    color={'inherit'}
                    size={18}
                />}
            </Button>
        </Stack>
    </Dialog>
}