import {FC} from "react";
import {TimeFilterPicker} from "./TimeFilterPicker";
import {Box} from "@mui/material";
import {TimeFilterProps} from "./TimeFilter.interface";
import {Dayjs} from "dayjs";

export const TimeFilter: FC<TimeFilterProps> = ({fromTime, setFromTime, toTime, setToTime}) => {
    const handleSetToTime = (date: Dayjs | null) => {
        if (date) {
            date = date.hour(23).minute(59).second(59);
        }
        setToTime(date);
    };

    return <Box display={'flex'} gap={1}>
        <TimeFilterPicker label={'Après le'} date={fromTime} setDate={setFromTime}/>
        <TimeFilterPicker label={'Avant le'} date={toTime} setDate={handleSetToTime}/>
    </Box>
}