import {FC, ReactNode} from "react";
import {Box, Stack} from "@mui/material";
import {Header} from "./Header";
import {BottomMenu} from "./BottomMenu";
import {useUser} from "../../features/user/useUser";
import {Login} from "../login/Login";

export const Page: FC<{children?: ReactNode}> = ({children}) => {
    const { token } = useUser()

    return <Stack
        maxWidth={'1000px'}
        width={'100%'}
        boxShadow={'0 0 20px rgba(0, 0, 0, 0.8)'}
        height={'100dvh'}
        mx={'auto'}
    >
        {token ? <>
            <Header/>
            <Box
                flexGrow={1}
                width={'100%'}
                sx={{
                    overflowY: 'auto'
                }}
            >
                {children}
            </Box>
            <BottomMenu/>
        </> : <Login/>}

    </Stack>
}