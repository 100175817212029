import { ManagePlayersAgentsState } from "./models/stateModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlayerAgentLoaders } from "./models/loaders";
import { User } from "../user/user";
import { ManagePlayersAgentsFilter } from "./models/filter";
import {LoaderAction} from "../common_funcs/loaderAction";

const initialState: ManagePlayersAgentsState = {
    users: null,
    pageSize: 100,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    filter: {
        fromTime: null,
        toTime: null,
        orderBy: null,
        userRole: null,
        sortDirection: null,
        banned: null,
        username: null
    },
    isShowUsersLoading: false,
    isCreateUserLoading: false,
    isDepositSending: false,
    isWithdrawLoading: false
}

export const ManagePlayersAgentsSlice = createSlice({
    name: 'managePlayersAgents',
    initialState,
    reducers: {
        setPlayersAgentLoader: (state, action: PayloadAction<LoaderAction<PlayerAgentLoaders>>) => {
            switch (action.payload.loader) {
                case PlayerAgentLoaders.isShowUsersLoading:
                    state.isShowUsersLoading = action.payload.isLoading
                    break
                case PlayerAgentLoaders.isCreateUserLoading:
                    state.isCreateUserLoading = action.payload.isLoading
                    break
                case PlayerAgentLoaders.isDepositSending:
                    state.isDepositSending = action.payload.isLoading
                    break
                case PlayerAgentLoaders.isWithdrawLoading:
                    state.isWithdrawLoading = action.payload.isLoading
                    break
            }
        },
        resetPlayersAgents: (state) => {
            state.users = null
            state.totalRecords = 0
            state.totalPages = 0
            state.currentPage = 1
            state.pageSize = 100
            state.filter = {
                fromTime: null,
                toTime: null,
                orderBy: null,
                userRole: null,
                sortDirection: null,
                banned: null,
                username: null
            }
            state.isShowUsersLoading = false
            state.isCreateUserLoading = false
            state.isDepositSending = false
            state.isWithdrawLoading = false
        },
        setPlayersAgents: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload
        },
        setPlayersAgentsFilter: (state, action: PayloadAction<ManagePlayersAgentsFilter>) => {
            state.filter = action.payload
        },
        setPlayersAgentsTotalRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setPlayersAgentsCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setPlayersAgentsItemsPerPage: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        updateUserBalance: (state, action: PayloadAction<{ user_id: number, balance: number }>) => {
            if (state.users) {
                state.users = state.users.map(user => {
                    if (user.user_id === action.payload.user_id) {
                        user.user_balance = action.payload.balance
                    }
                    return user
                })
            }
        },
        updateUserDepositRate: (state, action: PayloadAction<{ user_id: number, deposit_rate: number }>) => {
            if (state.users) {

                state.users = state.users.map(user => {
                    if (user.user_id === action.payload.user_id) {
                        user.deposit_rate = action.payload.deposit_rate
                    }
                    return user
                })
            }
        },
        changeUserBanState: (state, action: PayloadAction<{user_id: number, banned: boolean}>) => {
            if (state.users) {
                state.users = state.users.map(user => {
                    if (user.user_id === action.payload.user_id) {
                        user.banned = action.payload.banned
                    }
                    return user
                })
            }
        },

    }
})

export const {
    setPlayersAgentLoader,
    resetPlayersAgents,
    setPlayersAgentsCurrentPage,
    setPlayersAgentsTotalRecords,
    setPlayersAgentsFilter,
    setPlayersAgents,
    setPlayersAgentsItemsPerPage,
    updateUserBalance,
    updateUserDepositRate,
    changeUserBanState
} = ManagePlayersAgentsSlice.actions