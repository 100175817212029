import {FC} from "react";
import {Box, Typography} from "@mui/material";
import HouseIcon from '@mui/icons-material/House';
import {AddUserActivator} from "../add_user/AddUserActivator";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";
import {useUser} from "../../features/user/useUser";
import {UserRoleBadge} from "../UserRoleBadge";

export const Header: FC = () => {
    const {user, userRole} = useUser()

    return <Box
        minHeight={'56px'}
        sx={theme => ({
            backgroundColor: theme.palette.customColors.topBar.background,
            color: theme.palette.customColors.topBar.color,
        })}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        color={'white'}
        px={3}
    >
        <Box flex={1} component={Link} to={'/profile'}>
            <Box
                sx={{cursor: 'pointer'}}
                display={'flex'}
                alignItems={'center'}
                gap={0.4}
            >
                <AccountCircleOutlinedIcon/>
                <Typography
                    sx={{position: 'relative'}}
                    fontWeight={700}
                >
                    {user?.user_name}
                    {userRole && <Box position={'absolute'} top={'calc(100% - 5px)'} left={0}>
                        <UserRoleBadge userRole={userRole}/>
                    </Box>}
                </Typography>
            </Box>

        </Box>

        <Box
            flex={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={0.4}
        >
            <HouseIcon
                sx={{
                    fontSize: 22
                }}
            />
            <Typography
                variant={'h1'}
                fontWeight={700}
                fontSize={16}
            >
                Home
            </Typography>
        </Box>

        <Box flex={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <AddUserActivator/>
        </Box>
    </Box>
}