import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./app/themes/tunisia/theme";
import {Provider} from "react-redux";
import store from "./app/store";
import "@fontsource/poppins"
import {ApiErrorDialog} from "./app/components/common/ApiErrorDialog/ApiErrorDialog";
import './index.css'
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fr';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App/>
                <ApiErrorDialog/>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
    </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
