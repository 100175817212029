import {createTheme} from "@mui/material";

export const theme = createTheme({
    shape: {
        borderRadius: 0
    },
    typography: {
        fontFamily: 'Poppins, sans-serif'
    },
    palette: {
        mode: 'dark',
        text: {
            primary: '#ffffff',
        },
        primary: {
            main: '#ffc400',
            contrastText: '#000000',
        },
        customColors: {
            tableHeader: {
                background: '#1f231c',
                text: '#718355'
            },
            alternativeTableHeader: {
                background: '#2a2a2a',
                text: '#6c6c6c'
            },
            withdrawCell: {
                background: '#332525',
                text: '#bb4748'
            },
            netwinCell: {
                background: '#202934',
                text: '#0d6efd'
            },
            profileMenuIcon: {
                background: '#443626',
                color: '#ffffff'
            },
            bottomBar: {
                background: '#2a241f',
                color: '#ffffff'
            },
            topBar: {
                // background: '#ffc400',
                // color: '#000000'
                background: '#150b00',
                color: '#ffa700'
            },
            dialogHeader: {
                background: '#1f231c',
                color: '#ffffff'
            },
            tabBar: {
                background: '#1f231c',
                color: '#ffffff',
                knob: {
                    background: '#ffc400',
                    color: '#000000'
                }
            }
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
})