import {FC, ReactNode} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const DialogHeader: FC<{onCloseClick?: () => void, children?: ReactNode, title?: string}> = ({onCloseClick, children, title}) => {
    return <Box
        display={'flex'}
        alignItems={'center'}
        px={1}
        height={'56px'}
        borderBottom={'1px solid #e0e0e0'}
        sx={theme => ({backgroundColor: theme.palette.customColors.dialogHeader.background})}
    >
        {children !== undefined ? children : title ? <Box
            ml={'44px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexGrow={1}
        >
            <Typography sx={theme => ({color: theme.palette.customColors.dialogHeader.color})} fontWeight={700} fontSize={16}>
                {title}
            </Typography>
        </Box> : <Box flexGrow={1}/>}
        <IconButton onClick={onCloseClick} sx={{color: '#FF2727'}}>
            <HighlightOffIcon onClick={onCloseClick} sx={{fontSize: '28px'}}/>
        </IconButton>
    </Box>
}