import React, {FC} from "react";
import {Box, Button, CircularProgress} from "@mui/material";
import {DepositWithdrawProps} from "./DepositWithdraw.interface";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {usePlayersAgents} from "../../features/manage_players_agents/usePlayersAgents";
import {useUser} from "../../features/user/useUser";

export const DepositWithdraw: FC<DepositWithdrawProps> = ({amount, disabled, recipientID, onTransactionDone}) => {
    const { sendDeposit, withdrawMoney, isDepositSending, isWithdrawLoading } = usePlayersAgents()
    const {user} = useUser()

    const handleTransfer = (type: 'deposit' | 'withdraw') => () => {
        if (user && !disabled && amount && amount > 0 && !isDepositSending && !isWithdrawLoading) {
            if (type === 'deposit') {
                sendDeposit(recipientID, amount, user.user_id, onTransactionDone)
            } else {
                withdrawMoney(recipientID, amount, user.user_id, onTransactionDone)
            }
        }
    }

    return <Box
        display={'flex'}
        gap={1}
        p={2}
    >
        <Button
            fullWidth
            variant={'contained'}
            sx={{
                backgroundColor: '#1ab664',
                textTransform: 'none',
            }}
            onClick={handleTransfer('deposit')}
        >
            <ArrowUpwardIcon/>
            Dépot
            {isDepositSending && <CircularProgress
                color={'inherit'}
                size={18}
                sx={{ml: 1}}
            />}
        </Button>
        <Button
            fullWidth
            variant={'contained'}
            sx={{
                backgroundColor: '#FFB400',
                textTransform: 'none'
            }}
            onClick={handleTransfer('withdraw')}
        >
            <ArrowDownwardIcon/>
            Retrait
            {isWithdrawLoading && <CircularProgress
                color={'inherit'}
                size={18}
                sx={{ml: 1}}
            />}
        </Button>
        {/*{disabled ? 'disabled' : amount !== undefined ? amount : 'undefined'}*/}
    </Box>
}