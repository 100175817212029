import {FC, useEffect, useState} from "react";
import {Page} from "../components/layout/Page";
import {UserTable} from "../components/users/UserTable";
import {usePlayersAgents} from "../features/manage_players_agents/usePlayersAgents";
import {UsernameInlineFilter} from "../components/common/username_filter/UsernameFilter";
import {Stack} from "@mui/material";

export const HomePage: FC = () => {
    const [justMounted, setJustMounted] = useState(true)
    const { users, isShowUsersLoading, loadUsers, pageSize, currentPage, filter } = usePlayersAgents()

    const handleUsernameChanged = (username: string | null) => {
        loadUsers(currentPage, pageSize, null, {
            ...filter,
            username
        })
    }

    useEffect(() => {
        if (justMounted && users === null && !isShowUsersLoading) {
            loadUsers(currentPage, pageSize)
            setJustMounted(false)
        }
    }, [currentPage, filter, isShowUsersLoading, justMounted, loadUsers, pageSize, users])

    return <Page>
        <Stack p={1} gap={1}>
            <UsernameInlineFilter username={filter.username} onUsernameChanged={handleUsernameChanged}/>
            <UserTable/>
        </Stack>
    </Page>
}