import {FC, useMemo} from "react";
import {Box, Typography} from "@mui/material";
import {UserRole} from "../features/user/user";
import {userRoleToColor, userRoleToFrench} from "../features/user/userFuncs";

export const UserRoleBadge: FC<{userRole: UserRole}> = ({userRole}) => {
    const role: string = useMemo(() => userRoleToFrench(userRole), [userRole])
    const background = useMemo(() => userRoleToColor(userRole), [userRole])

    return <Box
        borderRadius={'5px'}
        px={0.4}
        sx={theme => ({
            backgroundColor: background,
            color: theme.palette.getContrastText(background)
        })}
    >
        <Typography fontSize={'10px'} lineHeight={1}>{role}</Typography>
    </Box>
}