import {User, UserRole} from "./user";

export const chooseName = (user: User | null) => {
    let name = user?.user_name ?? 'Noname'
    if (user) {
        if ((user.user_firstname && user.user_firstname !== '') || (user.user_lastname && user.user_lastname !== '')) {
            const firstLastName = []
            if (user.user_firstname) firstLastName.push(user.user_firstname)
            if (user.user_lastname) firstLastName.push(user.user_lastname)
            if (firstLastName.length > 0) {
                name = firstLastName.join(' ')
            }
        }
    }
    return name
}

export const stringToUserRole = (userRoleStr: string) : UserRole | null => {
    switch (userRoleStr.toUpperCase()) {
        case 'PLATFORM':
            return UserRole.PLATFORM
        case 'OWNER':
            return UserRole.OWNER
        case 'PARTNER':
            return UserRole.PARTNER
        case 'OWNERL':
            return UserRole.OWNERL
        case 'SUPERADMIN':
            return UserRole.SUPERADMIN
        case 'ADMIN':
            return UserRole.ADMIN
        case 'CASHIER':
            return UserRole.CASHIER
        case 'PLAYER':
            return UserRole.PLAYER
        case 'AGENT':
            return UserRole.AGENT
        default:
            return null
    }
}

export const userRoleToString = (userRole: UserRole) : string => {
    switch (userRole) {
        case UserRole.PLATFORM:
            return 'PLATFORM'
        case UserRole.OWNER:
            return 'OWNER'
        case UserRole.PARTNER:
            return 'PARTNER'
        case UserRole.OWNERL:
            return 'OWNERL'
        case UserRole.SUPERADMIN:
            return 'SUPERADMIN'
        case UserRole.ADMIN:
            return 'ADMIN'
        case UserRole.AGENT:
            return 'AGENT'
        case UserRole.CASHIER:
            return 'CASHIER'
        case UserRole.PLAYER:
            return 'PLAYER'
        default:
            return ''
    }
}

export const userRoleToFrench = (userRole: UserRole) => {
    switch (userRole) {
        case UserRole.PLATFORM:
            return 'Plateforme'
        case UserRole.OWNER:
            return 'Propriétaire'
        case UserRole.PARTNER:
            return 'Partenaire'
        case UserRole.OWNERL:
            return 'Propriétaire-L'
        case UserRole.SUPERADMIN:
            return 'Super admin'
        case UserRole.ADMIN:
            return 'Admin'
        case UserRole.AGENT:
            return 'Agent'
        case UserRole.CASHIER:
            return 'Caissier'
        case UserRole.PLAYER:
        default:
            return 'Joueur'
    }
}

export const userRoleToColor = (userRole: UserRole) => {
    switch (userRole) {
        case UserRole.PLATFORM:
            return '#8300b0'
        case UserRole.OWNER:
            return '#b0007e'
        case UserRole.PARTNER:
            return '#770154'
        case UserRole.OWNERL:
            return '#5b1100'
        case UserRole.SUPERADMIN:
            return '#ff2e00'
        case UserRole.ADMIN:
            return '#ef9b00'
        case UserRole.AGENT:
            return '#6ab000'
        case UserRole.CASHIER:
            return '#00c6d9'
        case UserRole.PLAYER:
        default:
            return '#003eb0'
    }
}

export const getSubRole = (userRole: string) : string => {
    const role = stringToUserRole(userRole)
    switch (role) {
        case UserRole.PLATFORM:
            return 'owner'
        case UserRole.OWNER:
            return 'partner'
        case UserRole.PARTNER:
            return 'ownerl'
        case UserRole.OWNERL:
            return 'superadmin'
        case UserRole.SUPERADMIN:
            return 'admin'
        case UserRole.ADMIN:
            return 'cashier'
        case UserRole.CASHIER:
        case UserRole.PLAYER:
        default:
            return 'player'
    }
}