import { configureStore } from "@reduxjs/toolkit"
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {UserSlice} from "./features/user/userSlice";
import {ErrorSlice} from "./features/apiErrors/errorSlice";
import {ManagePlayersAgentsSlice} from "./features/manage_players_agents/managePlayersAndAgentsSlice";
import {UserTransfersSlice} from "./features/user_transfers/UserTransfersSlice";

const store = configureStore({
    reducer: {
        errors: ErrorSlice.reducer,
        user: UserSlice.reducer,
        managePlayersAgents: ManagePlayersAgentsSlice.reducer,
        userTransfers: UserTransfersSlice.reducer,
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector