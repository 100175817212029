import { FC } from "react"
import { Page } from "../components/layout/Page"
import { Stack } from "@mui/material"
import {ProfileMenuItem} from "../components/common/profile_menu/ProfileMenuItem";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import {useUser} from "../features/user/useUser";

export const ProfilePage: FC = () => {
    const {logOut} = useUser()

    return <Page>
        <Stack
            boxShadow={'0 1px 5px #0002'}
            sx={{
                '& > *:not(:last-child) .profile-menu-item-divider': {
                    borderBottom: '1px solid #e0e0e0',
                }
            }}
        >
            <ProfileMenuItem link={'/profile/transactions'} icon={SyncAltIcon} title={'Historique des transactions'}/>
            <ProfileMenuItem link={'/profile/manage_password'} icon={VpnKeyOutlinedIcon} title={'Modifier mot de passe'}/>
            <ProfileMenuItem onClick={logOut} icon={ExitToAppOutlinedIcon} title={'Déconnexion'}/>
        </Stack>
    </Page>
}