import {FC} from "react";
import { DatePicker } from '@mui/x-date-pickers';
import {TimeFilterPickerProps} from "./TimeFilter.interface";

export const TimeFilterPicker: FC<TimeFilterPickerProps> = ({label, date, setDate}) => {


    return <DatePicker
        sx={{flex: 1}}
        label={label}
        value={date}
        onChange={(newValue) => setDate(newValue)}
        format="DD-MM-YYYY"
        disableFuture
    />
}