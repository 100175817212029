import {FC, useState} from "react";
import {Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { UserInfo } from "./UserInfo";
import {usePlayersAgents} from "../../features/manage_players_agents/usePlayersAgents";
import {convertCentsToCurrencyString} from "../../features/common_funcs/common_funcs";
import {User} from "../../features/user/user";
import {stringToUserRole, userRoleToFrench} from "../../features/user/userFuncs";

export const UserTable: FC = () => {
    const [openedUser, setOpenedUser] = useState<User|undefined>()
    const { users, isShowUsersLoading, totalRecords } = usePlayersAgents()

    const userClickHandler = (user: User) => () => {
        setOpenedUser(user)
    }

    return <>
        <TableContainer
            sx={theme => ({
                '& .MuiTableCell-head': {
                    backgroundColor: theme.palette.customColors.tableHeader.background,
                    color: theme.palette.customColors.tableHeader.text,
                },
                '& .MuiTableCell-body': {
                    fontWeight: 700,
                },
                '& .MuiTableCell-root': {
                    py: '10px',
                    fontSize: '13px'
                },
                '& .MuiTableCell-root:not(:last-child)': {
                    borderRight: '1px solid #e0e0e0'
                }
            })}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell size={'small'} align={'center'} width={'40%'}>
                            Utilisateur : {totalRecords}
                        </TableCell>
                        <TableCell size={'small'} align={'center'} width={'40%'}>
                            Solde : ---
                        </TableCell>
                        <TableCell size={'small'} align={'center'}>
                            Type
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users && !isShowUsersLoading && users.map((user) =>
                        <TableRow key={user.user_id} sx={{cursor: 'pointer'}} onClick={userClickHandler(user)}>
                            <TableCell size={'small'} align={'center'}>
                                {user.user_name}
                            </TableCell>
                            <TableCell size={'small'} align={'center'}>
                                {convertCentsToCurrencyString(user.user_balance)}
                            </TableCell>
                            <TableCell size={'small'} align={'center'}>
                                {(() =>{
                                    const role = stringToUserRole(user.user_role)
                                    if (role) {
                                        return userRoleToFrench(role)
                                    }
                                    return 'Unknown'
                                })()}
                            </TableCell>
                        </TableRow>)}

                    {users === null && isShowUsersLoading && <TableRow>
                        <TableCell size={'small'}><Skeleton width={'100%'}/></TableCell>
                        <TableCell size={'small'}><Skeleton width={'100%'}/></TableCell>
                        <TableCell size={'small'}><Skeleton width={'100%'}/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>

        {openedUser !== undefined && <UserInfo user={openedUser} close={() => setOpenedUser(undefined)}/>}
    </>
}