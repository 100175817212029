import {FC} from "react";
import {Box, Stack, Typography, useTheme} from "@mui/material";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { Link } from "react-router-dom";
import {useUser} from "../../features/user/useUser";
import {convertCentsToCurrencyString} from "../../features/common_funcs/common_funcs";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

export const BottomMenu: FC = () => {
    const {logOut, user} = useUser()
    const userBalance = user ? convertCentsToCurrencyString(user.user_balance) : ''
    const theme = useTheme()

    return <Box
        borderTop={'1px solid #e0e0e0'}
        minHeight={'56px'}
        sx={{
            backgroundColor: theme.palette.customColors.bottomBar.background,
            color: theme.palette.customColors.bottomBar.color,
            '& > *': {
                cursor: 'pointer'
            }
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        px={3}
    >
        <Stack
            to={'/'}
            component={Link}
            flex={1}
            alignItems={'center'}
        >
            <PeopleOutlineOutlinedIcon/>
            <Typography
                fontSize={10}

            >
                Clients
            </Typography>
        </Stack>
        <Stack
            to={'/profile'}
            component={Link}
            flex={1}
            alignItems={'center'}
            position={'relative'}
        >
            {user && <Box
                position={'absolute'}
                top={-5}
                left={'50%'}
                px={0.6}
                borderRadius={'8px'}
                sx={{
                    transform: `translateX(calc(-50% + 24px + (3px * ${userBalance.length})))`,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                }}
            >
                <Typography fontSize={12} fontWeight={700}>
                    {['platform', 'owner'].includes(user.user_role) ? <AllInclusiveIcon fontSize={'inherit'}/> : userBalance}
                </Typography>
            </Box>}
            <VpnKeyOutlinedIcon/>
            <Typography
                fontSize={10}

            >
                Compte
            </Typography>
        </Stack>
        <Stack
            onClick={logOut}
            flex={1}
            alignItems={'center'}
        >
            <ExitToAppOutlinedIcon/>
            <Typography
                fontSize={10}

            >
                Déconnexion
            </Typography>
        </Stack>
    </Box>
}